import {createTRPCProxyClient, TRPCClientError, httpLink} from "@trpc/client";
import type {AppRouter} from "backend/rpc/routers/index.js";
import * as auth from "@/auth";
import config from "@config";

export type {
  AppRouter
};

export function isTRPCClientError(err: unknown): err is TRPCClientError<AppRouter> {
  return err instanceof TRPCClientError;
}

let headers: Record<string, string> = {};

export function setClientHeaders(newHeaders: Record<string, string>) {
  headers = newHeaders;
}

export const client = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      async fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
        const res = await globalThis.fetch(input, init);
        if (res.status !== 401) return res;

        const cloned = res.clone();
        const body = await cloned.json();

        if (body.error?.data?.errorKey !== "UNAUTHENTICATED") return res;

        if (!auth.hasRefreshToken()) {
          return res;
        }

        console.info("Re-authenticating...");
        await auth.fetchAuthToken();

        // Retry request
        const requestOpt: RequestInit = {...init};
        if (!(requestOpt.headers instanceof Headers)) {
          requestOpt.headers = new Headers(requestOpt.headers);
        }

        for (const [k, v] of Object.entries(headers)) {
          requestOpt.headers.set(k, v);
        }

        return globalThis.fetch(input, requestOpt);
      },
      headers(opt) {
        if (opt.op.path.startsWith("public.")) return {};
        return headers;
      },
      url: `${config.apiUrl}/rpc`,
    }),
  ],
});

