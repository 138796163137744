import {client, setClientHeaders} from "@/apiClient";
import {Ref, ref, watchEffect} from "vue";

const refreshToken: Ref<string | null> = ref(null);
const accessToken: Ref<string | null> = ref(null);

watchEffect(() => {
  if (!accessToken.value) {
    setClientHeaders({});
    return;
  }

  setClientHeaders({"Authorization": accessToken.value});
}, {flush: "post"});

export function logout() {
  accessToken.value = null;
  refreshToken.value = null;
  localStorage.clear();
}

export async function restore() {
  const storedRefreshToken = localStorage.getItem("app_refreshToken");
  if (storedRefreshToken === null) return;

  refreshToken.value = storedRefreshToken;
  try {
    await fetchAuthToken();
  } catch(e) {
    console.warn("Failed to restore auth", e);
    refreshToken.value = null;
  }
}

export function setAuth(opt: {accessToken: string, refreshToken: string, store?: boolean}) {
  accessToken.value = opt.accessToken;
  refreshToken.value = opt.refreshToken;

  if (opt.store === false) return;
  localStorage.setItem("app_refreshToken", opt.refreshToken);
}

export function hasRefreshToken() {
  return refreshToken.value !== null;
}

export async function fetchAuthToken() {
  if (!hasRefreshToken()) throw Error("Can't refresh access token without refresh token");

  const res = await client.auth.refreshAuth.mutate({refreshToken: refreshToken.value!});
  accessToken.value = res.accessToken;
}
