import {createRouter, createWebHistory} from "vue-router";

import Index from "@/pages/public/Index.vue";
import DeleteIndex from "@/pages/public/DeleteIndex.vue";
import Login from "@/pages/public/Login.vue";
import Verify from "@/pages/public/Verify.vue";
import Delete from "@/pages/public/Delete.vue";

const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/poista-tili",
    component: DeleteIndex,
    children: [
      {
        path: "",
        component: Login
      },
      {
        path: "vahvistus/:verificationId",
        component: Verify,
        props: true
      },
      {
        path: "poisto",
        component: Delete
      }
    ]
  },
]

export const router = createRouter({
  routes,
  history: createWebHistory()
})
