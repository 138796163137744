import {Ref, ref} from "vue";
import {DeferredPromise, deferred} from "./util";

type DialogAlertParams = {
  kind: "alert";
  title: string;
  id: number;
  message: string;
};

type DialogConfirmParams = {
  kind: "confirmation";
  id: number;
  title: string;
  message: string;
}

type DialogLoaderParams = {
  kind: "loader"
};

type ToastType = "success" | "warn" | "error";

type ToastConfig = {
  id: number;
  type: ToastType;
  duration: number;
  text: string;
};

type ToastParams = {
  type?: ToastType;
  text: string;
  duration?: number;
}

type DialogTypes = DialogAlertParams | DialogConfirmParams | DialogLoaderParams;

export const activeToasts: Ref<ToastConfig[]> = ref([]);
export const activeDialog: Ref<DialogTypes | null> = ref(null);

let dialogId = 0;

export const closeDialog = () => activeDialog.value = null;
export const alert = (title: string, message: string) => activeDialog.value = {
  kind: "alert",
  message,
  title,
  id: dialogId++
};

const confirmations = new Map<number, DeferredPromise<boolean>>();
export const confirmation = (title: string, message: string) => {
  const promise = deferred<boolean>();
  const id = dialogId++;

  confirmations.set(id, promise);
  activeDialog.value = {
    kind: "confirmation",
    title,
    message,
    id,
  };

  return promise.promise;
};

export const resolveConfirmation = (id: number, result: boolean) => {
  const entry = confirmations.get(id);
  if (!entry) return;

  entry.resolve(result);
  activeDialog.value = null;
}

export const loader = () => activeDialog.value = {kind: "loader"};
let toastId = 0;
export const toast = (opt: ToastParams) => {
  const id = toastId++;

  const item: ToastConfig = {
    id,
    duration: 5000,
    type: "success",
    ...opt,
  };

  activeToasts.value.push(item);

  setTimeout(() => {
    const ind = activeToasts.value.findIndex(i => i.id === id);
    if (ind >= 0) activeToasts.value.splice(ind, 1);
  }, item.duration);
}
